.game {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: Arial, sans-serif;
  padding: 20px;
}

h2 {
  font-size: 28px;
  margin-bottom: 20px;
}

.validation-toggle {
  cursor: pointer;
}

.sudoku-board {
  display: grid;
  grid-template-rows: repeat(9, 1fr);
  gap: 1px;
  background-color: #000;
  border: 2px solid #000;
}

.sudoku-row {
  display: grid;
  grid-template-columns: repeat(9, 1fr);
  gap: 1px;
}

.sudoku-cell {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  font-size: 20px;
  cursor: default;
}

.sudoku-cell.editable {
  cursor: pointer;
}

.sudoku-cell.selected {
  background-color: #e0e0e0;
}

.sudoku-cell.correct {
  background-color: #90ee90;
}

.sudoku-cell.incorrect {
  background-color: #ffa07a;
}

.sudoku-cell.initial {
  font-weight: bold;
}

.sudoku-cell:nth-child(3n) {
  border-right: 2px solid #000;
}

.sudoku-row:nth-child(3n) .sudoku-cell {
  border-bottom: 2px solid #000;
}

.number-input {
  margin-top: 20px;
  display: grid;
  grid-template-columns: repeat(9, 1fr);
  gap: 5px;
}

.number-input button {
  width: 40px;
  height: 40px;
  font-size: 20px;
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  cursor: pointer;
}

.number-input button:hover {
  background-color: #e0e0e0;
}

.complete-message {
  margin-top: 20px;
  font-size: 24px;
  color: #4caf50;
  font-weight: bold;
}

.hint-buttons button {
  padding: 10px 20px;
  font-size: 16px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.hint-buttons button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.complete-message {
  margin-top: 20px;
  font-size: 24px;
  color: #4caf50;
  font-weight: bold;
}

.status-online {
  color: green;
  font-weight: 500;
  font-size: 14px;
  margin-left: 10px;
}

.status-offline {
  color: red;
  font-weight: 500;
  font-size: 14px;
  margin-left: 10px;
}

.back-to-work {
  background-color: rgb(2, 92, 2);
  color: white;
  font-size: 15px;
  font-weight: 500;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 16px; /* Equivalent to mt-4 */
}

.congratulation-message {
  margin-top: 20px;
  font-size: 24px;
  color: #4caf50;
  font-weight: bold;
  text-align: center;
}
