.input-icon-wrapper {
  border: 1px solid black;
}

.input-icon-wrapper input {
  outline: none;
  border: none;
  height: 34px;
}

.input-icon-wrapper .icon-container {
  align-items: center;
  padding: 2px 6px;
  gap: 2px;
}
.input-icon-wrapper .icon-container img {
  max-width: 100%;
  object-fit: contain;
  aspect-ratio: 1;
  width: 15px;
  height: 15px;
}
.input-icon-wrapper .icon-container:hover,
.input-icon-wrapper .icon-prev-item:hover {
  background-color: var(--border-color);
}

.icon-prev-container {
  position: absolute;
  border: 1px solid var(--border-color);
  z-index: 999;
  display: grid;
  place-items: center;
  grid-template-columns: repeat(4, 1fr);

  top: calc(100% + 10px);
  background-color: rgba(255, 255, 255, 1);
}

.icon-prev-item {
  padding: 10px 15px;
}

.input-icon-wrapper:has(+ .error-block) {
  border: 1px solid red !important;
}
