/* General styling for welcome page */
.welcome-page {
  padding: 20px;
  font-family: 'Poppins', sans-serif;
  display: flex;
  flex-direction: column;
  gap: 20px;
  border-radius: 4px;
  border: 1px solid #fff;
  background: #fff;
  margin: 17px;
  align-items: center;
  overflow-x: hidden;
  position: absolute;
  height: 87vh;
}

.tasks-summary-header {
  color: var(--main-text-color, #07082f);
  font-family: 'Plus Jakarta Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-bottom: 9px;
}

.tasks-summary-container {
  border-radius: 0px 10px 10px 10px;
  border: 1px solid var(--border, #f2f3f8);
  background: #f5f5f5;
  margin-bottom: 10px;
  padding: 15px;
  max-height: 165px;
  display: flex;
  flex-direction: column;
}

.tasks-summary {
  display: flex;
  gap: 10px;
}

.task-summary-wrapper {
  display: flex;
  overflow-x: scroll;
  overflow-y: hidden;
  scrollbar-width: none;
}

.task-card {
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 15px;
  width: 250px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: center;
  border-radius: 4px;
  border: 0.5px solid var(--hover, #eaebef);
  background: #fff;
  height: 106px;
  flex-shrink: 0;
}

.task-card .task-label {
  font-weight: 600;
  color: #555;
}

.task-summary-footer {
  position: absolute;
  top: -65px;
  left: 100%;
}

.flag-value {
  color: var(--main-text-color, #07082f);
  font-family: 'Plus Jakarta Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  display: flex;
  gap: 2px;
  align-items: center;
}

.task-info {
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 4px 0;
  color: var(--main-text-color, #07082f);
  font-family: 'Plus Jakarta Sans';
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 133.333% */
}
.profile-pic-container {
  border-radius: 50%;
  /* margin-right: 10px; */
  width: 28px;
  height: 28px;
}

.priority {
  color: red;
  font-weight: bold;
}

.notifications {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 10px;
}

.notification-item {
  background-color: #efefef;
  padding: 10px 15px;
  border-radius: 5px;
  font-size: 1rem;
  color: #555;
  width: fit-content;
  color: var(--main-text-color, #07082f);
  font-family: 'Plus Jakarta Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.suggestion-text {
  color: var(--Second-text-color, #67677f);
  font-family: 'Plus Jakarta Sans';
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 19.5px */
}
.ai-icon {
  position: relative;
  top: 32px;
  right: 42px;
}

.notification-item-wrapper {
  display: flex;
  align-items: center;
  gap: 4px;
}

/* hover effect */
.notification-item-wrapper:hover .notification-item {
  background-color: #e0e0e0;
}
.notification-item-arrow {
  display: none;
}

.notification-item-wrapper:hover .notification-item-arrow {
  display: block;
  cursor: pointer;
}
/* hover effect end*/

/* input box */
.ai-input-box-container {
  position: sticky;
  bottom: -12px;
  width: 67%;
}
.ai-input-box-container .taskDetails__chatBox--typeBox {
  border-radius: 50px !important;
}

.ai-chatBox--typeBox-btn {
  border-radius: 50px !important;
}

.typing-animation {
  overflow: hidden;
  border-right: 2px solid #000;
  white-space: nowrap;
  margin: 0 auto;
  letter-spacing: 0.15em;
  animation: typing 3.5s steps(40, end), blink-caret 0.75s step-end none;
}

.typing-animation::after {
  content: none; /* Remove the blinking cursor if it was added via pseudo-element */
}
.typing-animation::after {
  content: none;
  animation: blink 0.7s infinite;
}

.ai-body-container {
  min-height: 43vh;
}

/* Container styles */
.animated-text {
  font-family: 'Arial', sans-serif;
  font-weight: bold;
  font-size: 48px;
  text-align: left;
}

/* Keyframe for fading in the text and applying gradient effect */
@keyframes fadeInLeft {
  0% {
    opacity: 0;
    transform: translateX(-50px); /* Start off-screen slightly */
  }
  100% {
    opacity: 1;
    transform: translateX(0); /* Slide into view */
  }
}

/* Gradient for the first line */
.line1 {
  background: linear-gradient(90deg, #ffb300 0%, #2966f4 100%);
  -webkit-background-clip: text;
  color: transparent;
  opacity: 0; /* Initially hidden */
  animation: fadeInLeft 1s ease-in forwards;
  display: inline-block;
}

/* Gradient for the second line */
.line2 {
  background: linear-gradient(90deg, #ffb300 0%, #2966f4 100%);
  -webkit-background-clip: text;
  color: transparent;
  opacity: 0; /* Initially hidden */
  animation: fadeInLeft 1.5s ease-in forwards;
  display: inline-block;
  animation-delay: 0.5s; /* Delay the second line for smooth transition */
}

@keyframes blink {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}

.suggestions {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
}

.suggestion-btn {
  display: inline-flex;
  padding: 8px 10px;
  justify-content: center;
  align-items: center;
  opacity: 1; /* Initially hidden */
  /* animation: fadeInLeft 1.5s ease-in forwards; */
  animation-delay: 1s; /* Delay the second line for smooth transition */
  gap: 10px;
  border-radius: 100px;
  border: 1px solid var(--divider, #f0f0f0);
  background-color: #fff;
}

.suggestion-btn:hover {
  background-color: #e0e0e0;
}

.animated-line {
  opacity: 0;
  transform: translateY(20px);
  animation: fadeInUp 0.5s forwards;
}

@keyframes fadeInUp {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.loading-message {
  font-size: 16px;
  font-weight: bold;
  color: #666;
}

/* ai modal */
.audio-modal {
  width: 50%;
  min-height: 36vh;
  background: white;
  border-radius: 10px;
  margin-top: 50px;
  height: 75vh;
  display: flex;
  flex-direction: column;
}

/* ---- */

/* ---- */
.audio-record-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f8f9fd;
  padding: 20px;
  border-radius: 12px;
  width: 320px;
  margin: 0 auto;
}

.header {
  display: flex;
  justify-content: center;
  width: 100%;
}

.control-button {
  padding: 10px 20px;
  background-color: #ff4081;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  margin: 0 5px;
}

.visualizer {
  position: relative;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100px;
  overflow-x: hidden;
}

.wave-bar-container {
  display: flex;
  align-items: flex-end;
  margin-right: 2px;
}

.wave-bar {
  width: 4px;
  background-color: #3f51b5;
  margin: 0 1px;
  transition: height 0.1s ease;
}

/* ai modal */

/* bar  */
.outer-pulse {
  height: 110px;
  width: 110px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: radial-gradient(circle, rgba(195, 186, 186, 0.318) 60%, transparent 100%);
}

.white-ring {
  height: 90px;
  width: 90px;
  background-color: rgba(179, 168, 168, 0.5);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.black-circle {
  height: 60px;
  width: 60px;
  background-color: black;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mic-icon {
  cursor: pointer;
}

/* chat ai  */

.gradient-border {
  --borderWidth: 0.7px;
  position: relative;
  border-radius: 50%;
  padding: var(--borderWidth);
  background: #fcfcfc;
  box-shadow: 0px 0px 16px -4px lightblue;
  height: 120px;
  width: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.gradient-border:after {
  content: '';
  position: absolute;
  top: calc(-1 * var(--borderWidth));
  left: calc(-1 * var(--borderWidth));
  height: calc(100% + var(--borderWidth) * 2);
  width: calc(100% + var(--borderWidth) * 2);
  border-radius: 50%;
  z-index: -1;

  /* Adjust the color and blur here */
  border: 2px solid transparent;
  border-top: 2px solid rgba(86, 191, 250, 0.8);
  border-bottom: 2px solid rgba(86, 191, 250, 0.8);
  filter: blur(1px); /* Adds blur to the arcs */

  /* Animation for rotation */
  animation: rotate-arcs 5s linear infinite;
}

@keyframes rotate-arcs {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.listening-txt {
  color: #4a70aa;

  font-family: 'Plus Jakarta Sans';
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
  margin-top: 15px;
  text-align: center;
}

/* chat ai  */

/* Keyframes for the pulsing animation */
@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.1);
    opacity: 0.8;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

/* Apply animation when recording is true */
.animate {
  animation: pulse 1s infinite ease-in-out;
}
/* bar  */

/* canvas */
:root {
  --dot-size: 10px;
  --dot-width: 5px;
  --max-block-size: calc(var(--dot-size) * 5);
  /* --dot-color: lch(80.94% 62.75 148.82);
  --dot-color-transition-1: lch(59.2% 85.33 318.2);
  --dot-color-transition-2: lch(95.29% 85.33 97.41); */
  --dot-color: #a45cbb; /* A pinkish hue */
  --dot-color-transition-1: #00a0e8; /* A bright blue */
  --dot-color-transition-2: #eab8e4; /* A light pink */
  --delay: 0ms;
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: calc(var(--dot-size) / 2);
  block-size: var(--max-block-size);
}

.dot {
  inline-size: var(--dot-width);
  block-size: var(--dot-size);
  border-radius: calc(var(--dot-size) / 2);
  background: var(--dot-color);
  /* Initially no animation */
}

/* Different block-size for each dot */
.dot:nth-child(1) {
  block-size: calc(var(--dot-size) * 1); /* Different initial size */
}

.dot:nth-child(2) {
  block-size: calc(var(--dot-size) * 2.5); /* Different initial size */
}

.dot:nth-child(3) {
  block-size: calc(var(--dot-size) * 4); /* Different initial size */
}

.dot:nth-child(4) {
  block-size: calc(var(--dot-size) * 6); /* Different initial size */
}
.dot:nth-child(5) {
  block-size: calc(var(--dot-size) * 7); /* Different initial size */
}
.dot:nth-child(6) {
  block-size: calc(var(--dot-size) * 6); /* Different initial size */
}
.dot:nth-child(7) {
  block-size: calc(var(--dot-size) * 4); /* Different initial size */
}
.dot:nth-child(8) {
  block-size: calc(var(--dot-size) * 2.5); /* Different initial size */
}
.dot:nth-child(9) {
  block-size: calc(var(--dot-size) * 1); /* Different initial size */
}

.is-animating .dot {
  animation: y-grow 2s infinite ease-in-out;
}

@keyframes y-grow {
  25% {
    block-size: var(--max-block-size);
    background-color: var(--dot-color-transition-1);
  }
  50% {
    block-size: var(--dot-size);
    background-color: var(--dot-color-transition-2);
  }
}
/* canvas */

.action-container {
  display: flex;
  justify-content: center;
  border-end-end-radius: 10px;
  border-end-start-radius: 10px;
  align-items: flex-start;
  margin-bottom: 20px;
  margin-top: 10px;
  position: relative;
}

.audio-button {
  position: absolute;
  bottom: 7px;
  right: -50px;
  background: #e3e3e3;
  border-radius: 500px;
  z-index: 5;
  width: 38px;
  height: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.task-crd-container {
  display: flex;
  overflow: auto;
}

.section-container {
  height: 80%;
  display: flex;
  justify-content: center;
  overflow: auto;
}
.x-wrapper {
  cursor: pointer;
  color: #b2b2b2; /* Default color */
  transition: color 0.3s ease; /* Smooth transition for color change */
}

.x-wrapper:hover {
  color: black; /* Change this to your desired hover color */
  opacity: 0.6;
}

.ai-button {
  font-size: 15px;
  font-weight: 500;
  background: linear-gradient(76.16deg, #3454d1 36.42%, #e195f2 125.61%);
  color: white;
  padding: 4px 15px;
  border-radius: 200px !important;
  cursor: pointer;
}

.recording-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.loader-container {
  display: flex;
  margin-top: 5vh;
  justify-content: center;
}

.ai-content {
  flex-grow: 1;
  margin-top: 20px;
  display: flex;
  overflow: auto;
  flex-direction: column;
  height: 1vh; /* You may want to adjust this value */
}

.sch-sug-container {
  gap: 8px;
  display: flex;
  flex-direction: column;
}
.ai-container {
  padding: 5px;
  height: 100%;
}

/* MEDIA QUERY */

/* Mobile Responsive Design */
@media screen and (max-width: 768px) {
  .header h1 {
    font-size: 1.5rem;
  }

  .header p {
    font-size: 1.2rem;
  }
  .welcome-page {
    height: auto;
    top: 30px;
  }

  .tasks-summary {
    flex-direction: column;
    gap: 10px;
  }

  .task-card {
    width: 100%;
  }

  .notifications {
    flex-direction: column;
    gap: 5px;
  }

  .suggestions {
    flex-direction: column;
    gap: 10px;
  }
  .section-container {
    margin-top: 64px;
    display: block;
  }
  .header-ai {
    display: block !important;
  }
  .type {
    position: inherit !important;
    white-space: inherit !important;
  }
  .ai-container {
    padding: 16px;
  }
}
