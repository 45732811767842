.reminder-add-container {
  background: white;
  margin-top: 15%;
  padding: 1%;
  width: 32vw;
}

.reminder-list-container {
  max-width: 75vw;
  min-height: max-content;
  /* margin: 25px; */
  border-radius: 4px;
  border: 1px solid var(--divider, #f0f0f0);
  background: #fff;
}

.reminder-modal {
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.reminder-add-modal-container {
  min-width: 475px;
  min-height: 287px;
  flex-shrink: 0;
  border-radius: 10px;
  background: #fff;
  margin-top: 60px;
}
.date-field {
  padding: 2px;
  border: 0.5px solid var(--input-border);
  border-radius: 5px;
  min-width: 85px;
  height: 33.5px;
  display: flex;
  justify-content: center;
}

.datepicker {
  display: inline-flex;
  position: relative;
  overflow: clip;
  align-items: center;
}

.date-item {
  font-size: 13px;
}
.datepicker:focus-within {
  /* outline: black auto 2px; */
}

.datepicker > input[type='datetime-local'] {
  position: absolute;
  top: 0;
  left: 0;
  width: 300%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

.datepicker > input[type='datetime-local']::-webkit-calendar-picker-indicator {
  position: absolute;
  top: -150%;
  left: -150%;
  width: 300%;
  height: 300%;
  cursor: pointer;
}

.reminder-name {
  width: 206px;
  font-size: 13px;
}

.react-datepicker-popper {
  position: absolute;
  left: 0px;
  top: 0px;
  transform: translate(-41.6px, -284px);
}
.error-tooltip {
  right: 82px;
  position: fixed;
}
.reminder-date-container {
  left: 205px;
  z-index: 2;
}

@media screen and (max-width: 768px) {
  .reminder-container {
    margin-top: 90px;
  }
  .reminder-date-container {
    left: 148px;
    z-index: 2;
  }
  .reminder-list-container {
    max-width: max-content;
  }
  .reminder-add-modal-container {
    min-width: 350px;
    margin-top: 60px;
  }

  .reminder-add-modal-container .task-add-body {
    width: 22.3em;
  }
  .error-tooltip {
    right: 15px;
  }
}
