.okr_modal_view {
  min-height: 70vh;
  width: 40vw;
  background: white;
  border-radius: 2px;
  margin-top: 50px;
  /* max-height: 650px;
  overflow: scroll;
  overflow-x: hidden; */
  padding: 20px;
}

.icon_container {
  width: fit-content;
  height: 25px;
  font-size: 12px;
  gap: 5px;
  display: flex;
  border: 1px solid #ced4da;
  padding: 3px;
  align-items: center;
  border-radius: 4px;
}

.icon_drop_down_condainer {
  max-width: 290px;
  white-space: nowrap;
  padding: px;
}

.okr_modal_head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 13px 0px 15px;
  position: sticky;
  top: 0;
  background: white;
  z-index: 100;
}

.button_container {
  z-index: 100;
  position: sticky;
  bottom: 0;
  background: white;
  padding-bottom: 1rem;
}

.Letter_bg {
  width: 18px;
  height: 18px;
  background-color: #e6e5e5;
  border-radius: 20px;
  align-items: center;
  justify-content: center;
  display: flex;
  color: #878787;
  font-weight: 500;
  font-size: 11px;
  margin-top: 1px;
}

.binaryCondainer {
  width: 180px;
  height: 29px;
  border-radius: 15px;
  border: 1px solid #879096;
  padding: 3px;
  display: flex;
  justify-content: center;
  font-size: 12px;
  color: #879096;
  gap: 2px;
  font-weight: 400;
}

.binary_key_bg {
  width: 86px;
  height: 21px;
  border-radius: 9px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.binary_key_bg_active {
  background-color: #e8e8e8;
  color: #2a2e34;
  font-weight: 500;
}

.parent_condainer {
  justify-content: center;
  align-items: center;
  width: fit-content;
  font-weight: 500;
  font-size: 15px;
}

.okr_add_item_button {
  font-size: 10px;
  color: #879096;
  border-radius: 5px;
  padding: 4px 12px 4px 12px;
  cursor: pointer;
  border: none;
}

.okr_add_item_button:hover {
  background-color: #f2f1f1;
}

.rotate-down {
  transform: rotate(0deg);
  animation: rotateDown 300ms ease-in-out;
}

.rotate-up {
  transform: rotate(180deg);
  animation: rotateUp 300ms ease-in-out;
}

/* ui design  */

.objective-add-container {
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 100vh;
  height: 100vh;
}

.objective-add-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 25px 20px 0px 20px;
  position: relative;
  z-index: 99;
  /* box-shadow: 0 6px 5px -5px #091e4229; */
}
.okr-modal-view {
  background: white;
  border-radius: 10px;
  overflow: hidden;
  width: 600px;
  height: fit-content;
}

.okr-modal-content {
  --screen-height: 100vh;
  --top-padding: 30px;
  --button-space: 60px;
  --head-font-size: 18px;
  --offset-size: 120px;
  max-height: calc(
    var(--screen-height) - var(--top-padding) - var(--head-font-size) - var(--button-space) -
      var(--offset-size)
  );
  overflow-y: auto;
  padding: 10px 20px;
}

.objective-add-button-section {
  display: flex;
  /* justify-content: end; */
  justify-content: flex-end;
  align-items: center;
  padding: 15px;
  gap: 10px;
}
.visibility-container {
  cursor: pointer;
  width: fit-content;
  position: relative;
}
.okr-popup-head {
  font-size: 16px;
  font-weight: 600;
}
/* 
.create-okr-btn{
  display: flex;
width: 69px;
height: 25px;
padding: 8px;
border: 1px solid #f0f0f0;
justify-content: center;
align-items: center;
flex-shrink: 0;
} */
.okr-table-container {
  border-radius: 4px 4px 0px 0px;
  max-width: 100vw;
  margin: 20px;
  padding: 20px;
  border-radius: 4px;
  border: 1px solid var(--divider, #f0f0f0);
  background: #fff;
}

@media (max-width: 768px) {
  .okr-table-container {
    max-width: 100% !important;
    margin: 20px 18px;
  }
}

.add-icon-bg {
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: white; */
  border-radius: 4px;
  /* height: 35px;
  width: 35px;
  border: 1px solid #eaebef; */
  color: #87909e;
  cursor: pointer;
}

.add-icon-bg:hover {
  /* border: 1px solid #87909e; */
}

/* okr single view design  */

.objective-card {
  display: grid;
  grid-template-rows: auto auto auto 1fr;
  gap: 20px;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  border-radius: 4px;
  border: 1px solid var(--divider, #f0f0f0);
  background: #fff;
  margin: 20px;
  position: relative;
  width: 60vw;
  cursor: default;
}

.objective-head {
  display: flex;
  flex-direction: column;
}
.okr-name {
  color: var(--main-text-color, #2a2e34);
  font-size: 18px;
  font-weight: 600;
  line-height: 150%; /* 27px */
  line-break: auto;
}
.header {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 20px;
  align-items: center;
}

/* 
.okr-progress-parent {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  position: relative;
  border: 4px solid #ededed;
}

.okr-progress-child {
  position: absolute;
  border: 4px solid #ff6b6b;
  border-radius: 50%;
  width: 100%;
  height: 100%;
} */
.okr-progress-parent {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  position: relative;
  background-color: #ffe5e5; /* Light background color */
}

.okr-progress-child {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  clip: rect(0px, 60px, 60px, 30px); /* Clip the circle in half */
  background-color: transparent;
}

.okr-progress-child::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 5px solid #ff6b6b; /* Red border for the progress */
  clip: rect(0px, 30px, 60px, 0px); /* Clip to show only the left side */
  transform: rotate(72deg); /* Rotate to represent 20% */
}

.progress-text {
  position: absolute;
  font-size: 14px;
  color: #ff6b6b;
}

.okr-details {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(30%, 3fr));
  gap: 15px;
}

.okr-info {
  display: flex;
  gap: 20px;
}
.okr-view-label {
  font-weight: 500;
  font-size: 0.8125em;
  color: var(--Second-text-color);
  display: flex;
  gap: 10px;
  /* color: #87909E; */
}
.okr-view-value {
  font-weight: 500;
  font-size: 0.8125em;
  color: var(--main-text-color);
}

.okr-description {
  padding: 10px;
  border: 1px solid var(--divider, #f0f0f0);
  border-radius: 4px;
  font-size: 0.8125em;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* font-weight: ; */
}
/* 
.assignee {
  display: flex;
  align-items: center;
  gap: 10px;
} */
/* 
.avatar {
  width: 30px;
  height: 30px;
  background-color: #007bff;
  color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
} */

.sub-sections {
  display: grid;
  gap: 10px;
}

/* .objective-item {
  display: grid;
  grid-template-columns: auto 1fr auto auto;
  align-items: center;
  gap: 10px;
} */

.progress-bar {
  height: 5px;
  background-color: #ededed;
  border-radius: 5px;
  overflow: hidden;
}

.progress-bar > div {
  height: 100%;
  background-color: #ff6b6b;
}

.icon {
  width: 24px;
  height: 24px;
  background-color: #ff6b6b;
  color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.okr-status {
  display: flex;
  width: 65px;
  height: 23px;
  padding: 7px 10px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  flex-shrink: 0;
  border-radius: 100px;
  border: 1px solid var(--divider, #f0f0f0);
  background: #fff;
  font-size: 0.75em;
}

/* .in-progress {
  color: #ffa500;
} */

.key-result-info {
  display: flex;
  /* justify-content: space-between; */
  font-size: 13px;
  border-bottom: 1px solid #f0f0f0;
  padding: 7px 10px;
}

.key-result-info:last-child {
  border-bottom: none;
}

.key-result-info-parent {
  border: 1px solid var(--divider, #f0f0f0);
}
.popup-position {
  left: 0;
  right: 0;
  width: 160px;
}

.parent-info-head {
  border-radius: 3px;
  background: var(--icon-hover, #f3f5f7);
  margin: 5px 15px;
  padding: 5px 12px;

  display: flex;
  align-items: center;
  gap: 8px;
}
.okr-parent-separation {
  border-bottom: 1px solid #f0f0f0;
  margin: 15px 15px 0px;
}

/* existing task add pop up  */
.task-assign-popup-body {
  max-height: 150px;
  overflow-y: auto;
  padding: 5px 0px;
}

.plus-icon-outline {
  height: 20px;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;
  border-radius: 4px;
  color: #87909e;
}
.plus-icon-outline:hover {
  background: #f3f5f7;
  /* border: 1px solid; */
  color: #2a2e34;
}

.right-0px {
  right: 0;
}

.okr-edit-icon {
  position: absolute;
  top: 0px;
  right: 15px;
  height: 25px;
  width: 25px;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.okr-edit-icon:hover {
  background: #f3f5f7;
}

.okr-plus-icon {
  width: 20px;
  text-align: center;
  border-radius: 4px;
  padding-bottom: 2px;
}

.okr-name-input {
  min-height: 30px !important;
  max-height: 70px !important;
  overflow-y: auto;
  font-size: 18px;
  font-weight: 500;
}
