.active_tab {
  font-size: var(--active-tab-font-size);
  font-weight: var(--active-tab-font-weight);
  background-color: var(--sidebar-hover, #e5e5e5);
  text-decoration-line: none;
  padding: 8px 5px;
  border-radius: 5px;
  color: white;
  /* font-size: 16px; */
  display: flex;
  align-items: center;
  gap: 9px;
}
.active_tab:hover {
  color: white;
}
.non-active:hover {
}
.custom_tab {
  display: flex;
  align-items: center;
  gap: 8px;
}
.custom_tab_contain {
  display: flex;
  padding: var(--sidebar-item-padding);
  justify-content: space-between;
  border-radius: 5px;
  /* opacity: 0.5; */
}
.non-active:hover {
  background-color: #e5e5e5;
}

.custom_tab_sub {
  display: flex;
  padding: 8px 15px;
  justify-content: space-between;
  border-radius: 5px;
}

.tab-img {
  height: 14px;
  /* width: 16px; */
}
.non-active {
  font-size: var(--active-tab-font-size);
  font-weight: var(--in-active-tab-font-weight);
  display: flex;
  align-items: center;
  text-decoration-line: none;
  padding: 8px 5px;
  border-radius: 5px;
  color: black;
  /* font-size: 16px; */
  gap: 9px;
  /* opacity: 0.6; */
}

.nav_item_contain .active_tab,
.nav_item_contain .non-active {
  padding: var(--sidebar-item-padding);
}

.tab-active-font {
  /* font-size: var(--active-tab-font-size);
  font-weight: var(--active-tab-font-weight); */
  color: var(--sidebar-text);
}
.pin-active-font {
  /* font-size: var(--active-tab-font-size);
  font-weight: var(--active-tab-font-weight); */
  color: var(--sidebar-text);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.logout_contain {
  display: flex;
  justify-content: center;
  padding-bottom: 10px;
}
.logout_btn {
  border: none;
  padding: 5px 2px;
  border-radius: 5px;
  margin-top: 5px;
  width: 89%;
  background-color: #110000;
  cursor: pointer;
  color: white;
}
.tst {
  width: 100%;
  height: 100%;
  background: antiquewhite;
  position: absolute;
  z-index: 5;
  top: 0;
}

.global_container {
  height: 100svh;
  display: flex;
  flex-direction: column;
  /* overflow-y: hidden; */
}
.side_bar_outlet_contain {
  flex: 1;
  display: flex;
}
.outlet_contain {
  flex-grow: 1;
  overflow-x: hidden;
}

#hiddenDiv {
  /* display: none; */
  grid-template-rows: 0fr;
}
#pinnedDiv {
  /* display: none; */
  grid-template-rows: 0fr;
}
.hidden_contain {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.sub-menu-arrow {
  display: flex;
  justify-content: center;
  align-items: center;
}

#todo:checked + label + #hiddenDiv {
  /* display: block; */
  grid-template-rows: 1fr !important;
}
#pinned:checked + label + #pinnedDiv {
  /* display: block; */
  grid-template-rows: 1fr !important;
}

#pinned:checked + label .sub-menu-arrow {
  transform: rotateX(180deg);
}
#todo:checked + label .sub-menu-arrow {
  transform: rotateX(180deg);
}
#pinned + label .sub-menu-arrow {
  transform: rotateX(0deg);
  transition: transform 200ms ease-in-out;
}
#todo + label .sub-menu-arrow {
  transform: rotateX(0deg);
  transition: transform 200ms ease-in-out;
}

#pinned:checked + label .custom_tab_contain {
  opacity: 1;
}
#todo:checked + label .custom_tab_contain {
  opacity: 1;
}

#pinned {
  display: none;
}
#todo {
  display: none;
}

.non_active_sub {
  display: flex;
  /* justify-content: center; */
  align-items: center;
  text-decoration-line: none;
  padding: 8px 5px 8px 27px;
  border-radius: 5px;
  color: black;
  gap: 9px;
  opacity: 0.6;
  font-size: var(--active-tab-font-size);
  font-weight: var(--in-active-tab-font-weight);
  z-index: 99;
}

.active_tab_sub {
  background-color: #e5e5e5;
  text-decoration-line: none;
  padding: 8px 5px 8px 27px;
  border-radius: 5px;
  color: white;
  /* font-size: 16px; */
  display: flex;
  align-items: center;
  gap: 9px;
  font-size: var(--active-tab-font-size);
  font-weight: var(--active-tab-font-weight);
}
.active_tab_sub:hover {
  color: white;
}
.non_active_sub:hover {
  background-color: #e5e5e5;
}
.custom_tab_contain:hover {
  background-color: #e5e5e5;
}

.sub-menu-container {
  display: grid;
  margin-left: 16px;
  transition: grid-template-rows 300ms ease-in-out;
}

.company-select__control:hover {
  cursor: pointer;
  background-color: var(--border-color);
}

.side_bar_contain .quote .quote-text {
  color: rgba(135, 144, 158, 1);
  margin-top: 8px;
  font-size: 0.75rem;
}
.side_bar_contain .quote .title {
  font-size: 0.813rem;
  margin-top: 8px;
  /* white-space: nowrap; */
}
.side_bar_contain .quote .icon {
  max-width: 100%;
}

.side_bar_contain .quote {
  text-align: center;
  margin: 0 auto;
  width: 228px;
  background-color: rgba(255, 255, 255, 1);
  padding: 26px;
  border-radius: 3px;
  border: 1px solid rgba(241, 241, 241, 1);
}

.clickable {
  position: relative;
}

.clickable:hover::after {
  content: '';
  position: absolute;
  inset: 0;
  background-color: var(--border-color);
  border-radius: 50%;
  padding: 10px;
  scale: 1.8;
  transform: translate(-3%, -3%);
  z-index: -1;
}

.total_notification_count {
  height: 15px;
  border-radius: 10px;
  background-color: #ea4d4d;
  color: white;
  font-size: 10px;
  font-weight: 600;
  z-index: 10;
  display: flex;
  justify-content: center;
  padding: 0px 4px;
  align-items: center;
  min-width: 15px;
}
.pinned {
  font-size: 13px;
  font-weight: 500;
}

.chat-count {
  position: absolute;
  right: 10px;
  font-size: 10px;
  background: var(--Base-Color, --accent-color);
  padding: 1px 5px;
  border-radius: 5px;
  color: rgb(255, 255, 255);
}
