/* AddWidgetModal.css */

.widget-overlay {
  padding: 17px;
  height: 91vh;
}

.add-widget-heading {
  color: #2a2e34;
  leading-trim: both;
  text-edge: cap;
  font-family: 'Plus Jakarta Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 19px;
}
.modal-container {
  background: #ffffff;
  width: 304px;
  height: 1076px;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  overflow-y: auto;
}

.modal-container h3 {
  font-size: 18px;
  margin-bottom: 20px;
  color: #333;
}
/* 
.form-group {
  margin-bottom: 20px;
}

.form-group label {
  font-weight: bold;
  color: #555;
  margin-bottom: 10px;
}

.form-group select {
  width: 100%;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ccc;
} */

.columns-options {
  display: flex;
  gap: 8px;
}

.columns-options button {
  flex: 1;
  background-color: #fff;
  width: 83px;
  height: 34px;
  flex-shrink: 0;
  border-radius: 3px;
  border: 0.5px solid var(--empty-state, #d1d5d7);
  color: #c6c6c6;
  leading-trim: both;
  text-edge: cap;
  font-family: 'Plus Jakarta Sans';
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: flex;
  align-items: center;
  justify-content: center;
}

.columns-options .active {
  background: var(--Base-Color);
  color: white;
}

.radio-group {
  display: flex;
  gap: 10px;
  margin-top: 5px;
}

.radio-group label {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.radio-group input {
  margin-right: 6px;
}

.button-group {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  position: sticky;
  bottom: 0px;
}

.menu-item-outline-widget {
  height: 20px;
  width: 20px;
  display: flex;
  padding: 4px;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;
  border-radius: 4px;
}

.menu-item-outline-widget:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.cancel-btn,
.add-btn {
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.cancel-btn {
  background-color: #f0f0f0;
  color: #333;
}

.add-btn {
  background-color: #007bff;
  color: white;
}

.widget-label {
  color: var(--main-text-color, #07082f);
  leading-trim: both;
  text-edge: cap;
  font-family: 'Plus Jakarta Sans';
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.widget-label-radio {
  color: #2a2e34;
  leading-trim: both;
  text-edge: cap;
  font-family: 'Plus Jakarta Sans';
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.h-90vh {
  height: 90vh;
}

/* deadline css */
.deadlines {
  overflow-y: auto;
  width: 100%;
}
.dashboard-deadline-container {
  padding: 14px 7px 0px 7px;
}

.dashboard-deadline-progress {
  background-color: var(--hover);
  width: 100%;
  border-radius: 20px;
}

.deadline-text {
  color: var(--incomplete, #ea4d4d);
  font-family: 'Plus Jakarta Sans';
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px; /* 161.538% */
}

.deadline-info-container {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  padding-bottom: 20px;
  border-bottom: 1px solid var(--divider);
}

.deadline-owner-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 3px;
}

.deadline-date-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 3px;
  align-items: flex-end;
}

.deadline-task-heading {
  color: #2a2e34;
  font-family: 'Plus Jakarta Sans';
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.dashboard-status-widget {
  /* display: flex;
  flex-wrap: wrap;
  gap: 0.25rem; */
  display: flex;
  flex-wrap: nowrap;
  gap: 0.25rem;
  overflow: hidden;
}
.dashboard-status-widget:hover {
  overflow: auto;
  scrollbar-width: none;
}

.widget-format-option-label {
  color: var(--main-text-color, #07082f);
  font-family: 'Plus Jakarta Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.widget-format-option-container {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
.widget-drop-icon-container {
  height: 30px;
  width: 30px;
  border-radius: 3px;
  background: #bfbdbd;
  display: flex;
  align-items: center;
  justify-content: center;
}

.drop {
  top: 2.25rem;
}
.dashboard-widget-dropdown {
  width: 100px;
  height: auto;
  flex-shrink: 0;
  border-radius: 4px;
  background: #fff;
  position: relative;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  /* top: 20px;
  right: -24px; */
}
.dashboard-widget-dropdown-item {
  padding: 3px 10px;
  font-size: 12px;
  display: flex;
  align-items: center;
  gap: 0.25rem;
}
.dashboard-widget-dropdown-item:hover {
  background-color: var(--hover);
  cursor: pointer;
}
/* task statistics*/

.popover-statics {
  border-radius: 4px;
  background: #fff;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.12);
  display: flex;
  flex-direction: column;
  padding: 10px;
}
.popover-statics-heading {
  color: var(--main-text-color, #07082f);
  leading-trim: both;
  text-edge: cap;
  font-family: 'Plus Jakarta Sans';
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.popover-statics-content-text {
  color: var(--Second-text-color, #67677f);
  leading-trim: both;
  text-edge: cap;
  font-family: 'Plus Jakarta Sans';
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

/* dashboard projects*/

.dashboard_project-content {
  display: flex;
  flex-direction: column;
}

.dashboard_project-list {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: inherit;
  padding: 6px;
  overflow: auto;
  padding-bottom: 65px;
}
.dashboard_project-card {
  display: flex;
  align-items: center;
  padding: 10px 0px 10px 0px;
  border-bottom: 1px solid #f0f0f0;
  justify-content: space-between;
}
.dashboard_project-card:hover {
  background: #f1f1f1;
  cursor: pointer;
}

.dashboard_project-icon {
  background-color: #d9eaff;
  color: #0066ff;
  font-weight: bold;
  font-size: 16px;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin-right: 15px;
}
.dashboard_project-name {
  color: var(--main-text-color, #07082f);
  font-family: 'Plus Jakarta Sans';
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.dashboard_project-tasks-count {
  color: var(--Second-text-color, #67677f);
  font-family: 'Plus Jakarta Sans';
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.dashboard_project-status {
  margin-left: auto;
  display: flex;
  align-items: center;
  color: var(--main-text-color, #07082f);
  font-family: 'Plus Jakarta Sans';
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 153.846% */
}

.dashboard_project-status-dot {
  width: 8px;
  height: 8px;
  background-color: #ffcc00;
  border-radius: 50%;
  margin-right: 8px;
}
.dashboard_project-end-of-list {
  display: flex;
  justify-content: center;
  font-size: 12px;
  align-items: center;
  padding: 5px;
}

@media (max-width: 768px) {
  .dashboard-status-widget {
    flex-wrap: nowrap;
    overflow-x: auto;
  }
}
