.user-avatar {
  position: relative;
}

.avatar-tooltip {
  display: none;
  position: absolute;
  background-color: rgba(35, 35, 35, 0.69);
  color: white;
  padding: 2px 5px;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.user-avatar:hover + .avatar-tooltip {
  display: block;
  top: calc(100% + 10px);
}

.team-square {
  background-color: grey;
  border-radius: 3px;
}

.circular-block {
  background: #f1f2f4;
  color: black;
  font-size: 10;
  display: grid;
  place-items: center;
  cursor: pointer;
  /*Note: Don't add transition to any other property, If Need add another class */
  transition-duration: 250ms;
  transition-timing-function: ease-in-out;
  transition-property: width;
  z-index: 0;
}

.circular-block:hover {
  outline: 2px solid white;
  z-index: 10;
  position: relative;
  cursor: pointer;
}

.block-opened {
  width: 300px !important;

  /* min-height: 50px;
  transform: translateY(-10px); */
  min-width: 0px;
}

.stacked-image-add-controller {
  border: none !important;
  border-radius: 100px !important;
  border-color: transparent !important;
  box-shadow: none !important;
}

.border-indicator {
  align-self: stretch;
  width: 1px;
  background-color: hsl(0, 0%, 80%);
  margin-bottom: 8px;
  margin-top: 8px;
  box-sizing: border-box;
}

.squeeze-and-slide {
  animation: slide 300ms ease-in-out forwards;
}

.scale-up {
  transform: scale(0);
  animation: scaleUp 200ms cubic-bezier(1, 0.97, 0.75, 1.89) 50ms forwards;
}

@keyframes scaleUp {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}

@keyframes slide {
  0% {
    transform: translateX(0px);
    opacity: 1;
  }
  100% {
    transform: translateX(-100px);
    opacity: 0;
  }
}

.check-container:hover svg {
  /* stroke: green; */
}

.check-container-button-effect {
  animation: zoomInOut 2000ms ease-in-out infinite;
}

@keyframes zoomInOut {
  0% {
    scale: 1;
  }
  50% {
    scale: 0.7;
  }
  100% {
    scale: 1;
  }
}

.check-container[aria-disabled='true']:hover svg {
  stroke: #cfcfcf !important;
}

.smak-avatar-react-select .right-shadow {
  box-shadow: inset -5px 1px 4px -5px #60606040;
}

.block-closed .check-lucid {
  opacity: 0;
  transition: opacity 200ms;
}

.avatar-remove-input {
  display: none;
  background-color: white;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  /* display: flex; */
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: absolute;
  right: -6px;
}

.avatar-remove-input:hover {
  background-color: #cfcfcf;
}

/* div:has(.selected-avatar-container):hover .avatar-remove-input {
  display: flex;
} */

.avatar-n-remove-container:hover .avatar-remove-input {
  display: flex;
}

.clearable-avatar:hover .avatar-delete {
  display: flex;
}

.avatar-delete {
  display: none;
  position: absolute;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.3);

  margin-left: -3px;
  z-index: 99;
  border-radius: 50%;
}

.preview-list-container {
  display: flex;
  scrollbar-width: none;
}

.stacked-relative {
  position: relative;
}
