.header-ai {
  display: grid;
  grid-template-columns: max-content;
  gap: 0px;
  align-items: center;
  margin-bottom: 15px;
}

.header-ai .highlight {
  color: #f0a500;
}

.header-ai p {
  font-family: 'Plus Jakarta Sans';
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  /* background: linear-gradient(90deg, #ffb300 0%, #2966f4 100%); */
  background: linear-gradient(90deg, #56bffa 0%, #ec97f4 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

@keyframes blink {
  0% {
    border-right-color: black;
  }
  50% {
    border-right-color: transparent;
  }
  100% {
    border-right-color: black;
  }
}

/* Base typing style */
.type {
  position: relative;
  font-family: Consolas, Monaco, monospace;
  white-space: nowrap;
  overflow: hidden;
  border-right: 2px solid transparent; /* Hide cursor by default */
}

/* First line typing effect with a visible cursor */
.first-line {
  width: 0;
  animation: typing 1.5s steps(50, end) forwards, blink 0.75s step-end infinite;
  border-right: 2px solid black; /* Show cursor while typing */
}

/* Remove cursor after typing the first line */
.first-line.finished {
  animation: typing 1.5s steps(50, end) forwards;
  border-right: none; /* Hide cursor when typing is finished */
}

/* Second line typing effect, delayed after the first line */
.second-line {
  width: 0;
  animation: typing 1.5s steps(50, end) forwards, blink 0.75s step-end infinite;
  animation-delay: 1.7s; /* Delay to start after first line finishes */
  border-right: 2px solid black; /* Show cursor while typing second line */
  visibility: hidden; /* Hide initially */
}

.second-line.visible {
  visibility: visible; /* Show when ready to start typing */
}

/* Remove cursor after typing the second line */
.second-line.finished {
  animation: typing 1.5s steps(50, end) forwards;
  border-right: none; /* Hide cursor when typing is finished */
}

/* =============== */
.message-container {
  border-radius: 10px;
  border: 1px solid var(--border, #f2f3f8);
  background: rgb(245, 245, 245);
  padding: 10px;
  display: flex;
  /* flex-direction: column; */
  position: relative;
  margin-bottom: 10px;
  cursor: pointer;
}
.message-container-list {
  border-radius: 10px;
  border: 1px solid var(--border, #f2f3f8);
  background: rgb(245, 245, 245);
  padding: 10px;
  display: flex;
  /* flex-direction: column; */
  position: relative;
  margin-bottom: 10px;
  cursor: pointer;
  /* width: 100%; */
}

.message-container .navigate-arrow {
  opacity: 1;
  transition: opacity 0.3s ease;
}
.message-container-list .navigate-arrow {
  opacity: 1;
  transition: opacity 0.3s ease;
}

.message-container.hover-based .navigate-arrow {
  opacity: 0;
}
.message-container-list.hover-based .navigate-arrow {
  opacity: 0;
}

.message-container.hover-based:hover .navigate-arrow {
  opacity: 1;
}
.message-container-list.hover-based:hover .navigate-arrow {
  opacity: 1;
}

.message-container-list:hover {
  background-color: #e2e2e2;
}
