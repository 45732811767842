.pointer {
  cursor: pointer;
}

.chat-bg {
  --chat-box-sent-color: #ecf3fe;
  --chat-box-received-color: #f7f8f9;
  --chat-box-sent-border-color: #e4eefe;
  /* background: #fafafb; */
  min-height: 75vh;
  min-width: 25vw;
  border-left: 1px solid var(--border-color);
  padding: 0px 15px;
  display: flex;
  flex-direction: column;
  /* background-color: #f7f9fa91; */
  position: sticky;
  top: 60px;
  /* background-color: #fff; */
  border-radius: 0px 4px 4px 0px;
}
.chat-content {
  scrollbar-gutter: stable;
  /* margin-bottom: 10px; */
  padding-inline: 0.9375em;
  flex: 1;
  overflow-y: hidden;
  overflow-x: hidden;

  display: flex;
  flex-direction: column-reverse;
  /* min-height: calc(100vh - 190px); */
  /* max-height: calc(100vh - 190px); */
  /* overflow: scroll; */
}
.chat-content:hover {
  overflow-y: auto;
}
.message-list-wrapper {
  display: flex;
  flex-direction: column;
}

.chat-top-container {
  --update-padding: 22px;
  display: grid;
  grid-template-columns: 398px auto;
  margin: 23px;
  border: 1px solid var(--border-color);
  border-radius: 4px;
  background: #fff;
}

.welcome-container {
  border: 1px solid var(--border-color);
}

.task-chat-container {
  border: 0.5px solid var(--border-color);
  border-left: none;
  z-index: 8;
}
.chat-list-task {
  padding-bottom: 10px;
  padding-inline: 0.9375em;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  overscroll-behavior: contain;
  display: flex;
  flex-direction: column-reverse;
  min-height: 200px;
  /* max-height: calc(100vh - 260px); */
  /* overflow: auto; */
}

@media (max-width: 1110px) {
  .chat-list-task {
    overscroll-behavior: auto;
  }
}

.taskDetails__chatBox--typeBox {
  width: 100%;
  height: 50px;
  border: 1px solid #f0f0f0;
  background-color: #fff;
  border-radius: 6px !important;
  padding-left: 20px;
  padding-right: 10px;
  align-items: center;
}

.chat-input-container {
  padding: 0px 0.9375em 0.9375em 0.9375em !important;
}
.bottom-row {
  display: flex;
  position: relative;
  bottom: 0;
  width: 100%;
  align-items: flex-end;
}

.chat_textarea {
  max-height: 120px;
  resize: none;
  border: none;
  font-size: 12px;
  width: 100%;
  flex: 1;
}

.chat_textarea:focus {
  border: none;
  outline: none;
}
.chat_textarea:focus-visible {
  border: none;
  outline: none;
}

.icon_bg {
  width: 30px;
  height: 30px;
  background-color: #f1f1f1;
  border-radius: 20px;
  align-items: center;
  justify-content: center;
  display: flex;
  font-weight: 500;
  font-size: 11px;
  margin-top: 1px;
}
.icon_bg_inside_chat {
  width: 40px;
  height: 40px;
  background-color: #d9d9d9;
  border-radius: 20px;
  align-items: center;
  justify-content: center;
  display: flex;
  font-weight: 500;
  font-size: 11px;
  margin-top: 1px;
}

.anim-slide-show {
  display: grid;
  grid-template-rows: 0fr;
  animation: grid-open 300ms ease-in-out forwards;
}

@keyframes grid-open {
  0% {
    grid-template-rows: 0fr;
    padding: 0;
  }
  100% {
    grid-template-rows: 1fr;

    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
}

.sent-btn {
  border: none;
  padding: 3px 10px;
  border-radius: 3px;
  background-color: #879096;
  cursor: pointer;
  display: flex;
  gap: 3px;
  font-size: 13px;
  align-items: center;
  justify-content: center;
  color: white;
  width: 45px;
  height: 31px;
  background: var(--Base-Color, --accent-color);
}

.chat_menu_popup {
  z-index: 9999;
  height: -moz-fit-content;
  height: fit-content;
  width: 144px;
  background: white;
  position: absolute;
  bottom: calc(100% + 5px);
  border-radius: 5px;
  box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.2);
  padding: 10px;
  font-size: 13px;
  color: #879096;
}

.chat_menu_items {
  height: 35px;
  display: flex;
  align-items: center;
  gap: 12px;
  padding-left: 5px;
}
.chat_menu_items:hover {
  background: rgba(0, 0, 0, 0.075);
}

.attachement_condainer_outgoing {
  width: 300px;
  height: 70px;
  background-color: #f2f2f2;
  padding: 12px;
  font-weight: 400;
  font-size: 13px;
  margin-left: auto !important;
  border-radius: 10px;
  border-top-right-radius: 0;
}
.attachement_condainer_incoming {
  width: 300px;
  height: 90px;
  background-color: #f2f2f2;
  padding: 5px 12px 12px 12px;
  font-weight: 400;
  font-size: 13px;
  border-radius: 10px;
  border-top-left-radius: 0;
}
.request_attachement_condainer_outgoing {
  width: 300px;
  height: 135px;
  background-color: #f2f2f2;
  padding: 12px;
  font-weight: 400;
  font-size: 13px;
  border-radius: 10px;
  border-top-right-radius: 0;
  margin-left: auto !important;
}
.request_attachement_condainer_incoming {
  width: 300px;
  height: 155px;
  background-color: #f2f2f2;
  padding: 5px 12px 12px 12px;
  font-weight: 400;
  font-size: 13px;
  border-radius: 10px;
  border-top-left-radius: 0;
}
.link_attachement_condainer_outgoing {
  width: 300px;
  height: 250px;
  background-color: #f2f2f2;
  /* padding: 3px 8px 8px 8px; */
  font-weight: 400;
  font-size: 13px;
  border-radius: 10px;
  border-top-right-radius: 0;
  margin-left: auto !important;
}
.link_attachement_condainer_incoming {
  width: 300px;
  height: 270px;
  background-color: #f2f2f2;
  /* padding: 3px 8px 8px 8px; */
  font-weight: 400;
  font-size: 13px;
  border-radius: 10px;
  border-top-left-radius: 0;
}
.link_top_condainer {
  width: 100%;
  height: 65px;
  background-color: #2a27270f;
  border-radius: 0px 0px 10px 10px;
  padding: 12px;
  font-weight: 400;
  font-size: 11px;
  line-break: anywhere;
}
.link_bottom_condainer {
  width: 100%;
  height: 65px;
  padding: 12px;
  font-weight: 400;
  font-size: 11px;
  line-break: anywhere;
}
.link_img_condainer {
  width: 100%;
  height: 100px;
  object-position: center;
  overflow: hidden;
}

.link_img {
  max-width: 100%;
  object-fit: cover;
  overflow: hidden;
}

.chat_small_font {
  font-size: 9px;
  color: #626f86;
}
.chat_reminder_font {
  font-size: 12px;
  color: #626f86;
}

.audio-bar-parent {
  background-color: #d9d9d9 !important;
  width: 170px;
  height: 3px;
  position: relative;
  border-radius: 10px;
}

.audio-bar-child {
  /* --progress-width: 30%; Default width */
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  /* width: var(--progress-width); Use the CSS variable here */
  background-color: red;
  border-radius: 10px;
}

.chatBox_msg__outgoingimg {
  position: relative;
  color: white;
  background: #e9e9e9;
  padding: 10px 20px;
  border-radius: 10px;
  border-top-right-radius: 0;
  margin: 5px 0px;
  min-width: 50px;
  width: fit-content;
  max-width: 300px;
  /* margin-left: auto; */
  border: 1px solid #f0f0f0;

  /* box-shadow: 2px 1px 10px 1px #00000014; */
}
.fz-13px {
  font-size: 0.8125em;
}
.msg-card-container {
  padding: 4px 6px;
  border-radius: 2px;
}
.msg-card-container:hover,
.msg-card-container:focus,
.msg-card-container:focus-within {
  background-color: rgba(247, 247, 247, 1);
}
.msg-card-container:hover .msg-more-options {
  display: block !important;
  visibility: visible !important;
}

.fz-10px {
  font-size: 0.625em;
}
.fz-14px {
  font-size: 0.875em;
}

.fw-400 {
  font-weight: 400;
}

.fw-500 {
  font-weight: 500;
}

.fw-600 {
  font-weight: 600;
}
.fw-800 {
  font-weight: 800;
}

.mt-5px {
  margin-top: 5px;
}

.grey-txt {
  color: rgba(135, 144, 150, 1);
}

.chatBox_msg__incoming {
  position: relative;
  color: black;
  background-color: white;
  padding: 10px 20px;
  border-radius: 10px;
  border-top-left-radius: 0;
  margin: 1px 1px;
  min-width: 50px;
  width: fit-content;
  max-width: 300px;
  border: 1px solid #f0f0f0;
  /* box-shadow: 2px 1px 10px 1px #00000014; */
}

.chat_msg_name {
  font-size: 13px;
  font-weight: 500;
}

.chat_msg {
  font-size: 13px;
  color: #879096;
}

.audio_sent-btn {
  border: none;
  padding: 2px 16px;
  border-radius: 3px;
  /* background-color: #879096; */
  cursor: pointer;
  display: flex;
  gap: 3px;
  font-size: 13px;
  align-items: center;
  justify-content: center;
  color: white;
  width: 50px;
  height: 31px;
  background: var(--accent-color);
}

.update-padding {
  padding: 0px var(--update-padding, 22px);
}

.chat_list_condainer {
  border-bottom: 1px solid var(--border-color);
  border-radius: 3px;
  /* padding: 10px; */
  /* width: 25vw; */
  max-height: calc(100vh - 55px);
}
.list_condainer {
  /* height: 72vh; */
  overflow-y: hidden;
  margin-top: 20px;
  scrollbar-gutter: stable;
}

.list_condainer:hover {
  overflow-y: auto;
}

.chat_menu_active {
  font-weight: 500;
  font-size: 13px;
  color: black !important;
  text-decoration: none;
  /* background: red; */
  border-bottom: 1.8px solid black;
  padding: 0px 2px 2px 2px;
}
.chat_menu_inactive {
  font-weight: 400;
  font-size: 13px;
  color: black;
  text-decoration: none;
  /* background: red; */
  padding: 0px 2px 2px 2px;
  opacity: 0.5;
}
.chat_menu_inactive:hover {
  color: black;
  opacity: 0.9;
}

.dim-container {
  opacity: 0.5 !important;
}

.chat_header_condainer {
  width: 100%;
  height: 60px;
  border-bottom: 1px solid var(--border-color);
  background-color: #fff;
  border-radius: 6px;
  align-items: center;
}
.chat_header_icon_condainer {
  /* width: 100px; */
  display: none;
  margin: auto 5px;
}

/* Left */

.chat-request-icon-container {
  position: relative;
}

.chat-request-container-backdrop {
  position: fixed;
  inset: 55px 0px 0;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: flex-end;
  padding-bottom: 50px;
}

.chat-request-container {
  font-family: var(--Inter);
  /* position: absolute; */
  top: 100%;
  right: 10px;
  background-color: white;
  padding: 10px 20px;
  gap: 10px;
  border: 1px solid #ededed;
  z-index: 999999;
  /* margin-left: 16em; */
  flex-wrap: wrap;
  max-height: 100vh;
  height: fit-content;
  overflow: auto;

  display: grid !important;
  /* grid-template-columns: 1fr 2fr; */
  transition: all 200ms;
  overflow: hidden;
  width: calc(100% - 15rem);
}

.chat-req-grid-one-column {
  width: fit-content;
  grid-template-columns: auto 0fr;
}

.chat-req-grid-two-column {
  width: calc(100% - 16rem);
  grid-template-columns: auto 1fr;
}

.chat-user-list-container {
  padding: 10px 15px;
  user-select: none;
  flex: 1;
}

.chat-head-content {
  padding: 0 0.9375em 0 0.9375em !important;
}

.chat-window {
  background-color: #f9f9f9;
  border: 1px solid #00000014;
  /* min-width: 500px; */
  flex: 1;
  /* padding: 10px 15px; */
  /* padding:0px 16px 16px 16px; */
}

@media only screen and (max-width: 600px) {
  .chat-window {
    min-width: auto;
  }

  .chat-request-container {
    margin-left: 0;
  }

  .chat-user-list-time {
    display: none;
  }

  .chat-user-list-time {
    display: none;
  }
  .chat_header_icon_condainer {
    display: flex;
  }
  .group-creation-pop-up {
    right: 0px !important;
    left: unset !important;
  }
  .chat-content {
    overflow-y: auto;
  }
}

.chat-user-list-title {
  font-weight: 600;
  font-size: 15px;
  font-family: var(--Inter);
}

.chat-suer-list-sub-title {
  color: #0000003d;
  font-size: 13px;
}

.chat-user-list-name {
  /* font-weight: 500; */
  /* font-size: 11px; */
  font-family: var(--Inter);
  /* max-width: 150px; */
  min-width: 100%;
  width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.ellipsis-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.chat-user-list-time {
  color: #2a2e34;
  font-size: 10px;
}

.chat-user-list-last-msg {
  color: #5c5f62;
  /* font-size: 13px; */
  /* max-width: 230px; */
  min-width: 100%;
  width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.chat-user-list {
  padding: 12px 5px;
  /* margin: 5px 0px; */
  border-radius: 4px;
}

.chat-user-update-padding {
  padding: 9px calc(var(--update-padding) / 2) !important;
  margin: 0px calc(var(--update-padding) / 2) !important;
}

.user-list-active {
  background-color: #eceff4 !important;
}

.update-root-container .chat-user-list:hover {
  /* background-color: #f4f4f4; */
  background-color: #f3f4f6;
}

.user-list-heading {
  font-size: 10px;
  color: #5c5f62;
  position: sticky;
  background-color: #fff;
  top: 0;
}

.chat-user-list-msg-count {
  width: 20px;
  height: 20px;
  border-radius: 20px;
  background: var(--Base-Color, --accent-color);
  color: #ffffff;
  font-size: 9px;
  font-weight: 500;
  display: grid;
  place-items: center;
}

.chat-grey-box {
  background-color: #f7f8f9 !important;
}

.msg-count-container {
  /* width: 32px;
  margin: auto 5px; */
  white-space: nowrap;
}

.chat-window-content {
  flex: 1;
  min-height: 400px;
  display: flex;
  flex-direction: column-reverse;
  /* padding: 16px 0px; */

  overflow: auto;
  margin-bottom: 16px;
  padding: 16px;
  max-height: calc(100vh - 150px);
  /* gap:10px */
  position: relative;
}

.max-height-with-border {
  border-bottom: 1px solid #00000014;
  max-height: calc(100vh - 300px) !important;
}

.ml-neg-15 {
  margin: auto;
  margin-left: -15px !important;
}

.attachment-preview {
  overflow: auto;
  gap: 5px;
  padding: 0px 5px;
}

.chat-window-msg-container {
  font-family: var(--Inter);
  padding: 10px 16px;
  border-radius: 4px;
  font-size: 13px;
  max-width: 400px;
  white-space: pre-wrap;
  overflow-wrap: break-word;
  /* width: fit-content; */
}

.chat-msg-sender {
  background-color: #365eff;
  color: #ffffff;
}

.chat-msg-receiver {
  background-color: #ffffff;
  color: #5c5f62;
  border: 1.2px solid rgba(0, 0, 0, 0.12);
}

.gap-5px {
  gap: 5px;
}
.gap-4px {
  gap: 4px;
}

.chat-msg-grey-text {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.24);
}

.chat-window-head-container {
  border-bottom: 1px solid #00000014;
  margin: 0 16px 0px 16px;
  padding: 12px 16px;
}

.chat-status {
  color: #00b485;
  font-size: 14px;
}

.chat-window-head-name {
  font-size: 14px;
  font-weight: 500;
  font-family: var(--Inter);
}

.chat-send-btn {
  padding: 6px 8px;
  border: 1px solid #5c5f62;
  border-radius: 4px;
  color: #5c5f62;
  background-color: transparent;
  gap: 4px;
  display: flex;
  font-weight: 600;
  font-size: 12px;
}

.chat-window-editor {
  background-color: white;
  padding: 5px;
  border-radius: 4px;
  font-size: 14px;
  border: 1.2px solid rgba(0, 0, 0, 0.16);
}
.chat-window-wrapper {
  background-color: transparent;
  display: flex;
  flex-direction: column-reverse;
  padding: 5px;
}
.chat-window-editor-toolbar {
  background-color: transparent !important;
  border: none !important;
  margin-top: 10px;
}

.cursor-pointer {
  cursor: pointer;
}

.opacity-0 {
  opacity: 0;
}

.message-card-container {
  animation-delay: 300ms;
}

.fade-in {
  opacity: 0;
  animation-name: fadeIn;
  animation-duration: 200ms;
  animation-fill-mode: forwards;
}

.blink {
  animation: blinkContent 1500ms ease-in-out infinite !important;
}

@keyframes blinkContent {
  0% {
    opacity: 0.2;
  }
  50% {
    opacity: 0.6;
  }
  100% {
    opacity: 0.2;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* END LEFT */

.chat-attachment-preview {
  position: absolute;
  bottom: calc(100% + 10px);
  /* background: white; */
  width: 100%;
  scrollbar-width: thin;
  overflow: auto;
  left: 0;
  /* outline: 1px solid red; */
  height: 80px;
}

.msg-sent-status {
  position: absolute;
  bottom: 2px;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 13px;
  height: 13px;
  right: 0px;
}

.h-fit-content {
  height: fit-content;
}

.img-overlay-load {
  position: absolute;
  inset: 0;
  z-index: 999;
  background-color: #0000008c;
  place-items: center;
  display: grid;
}

/* .img-prev-container {
  max-width: 100%;
  border-radius: 9px;
  overflow: hidden;
}

.img-prev-container img {
  object-fit: cover;
}

.chat-attachment-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(min(50px, 100%), 1fr));
  gap: 10px;
} */

/* Test */
.chat-attachment-container {
  display: grid;
  gap: 10px;
  width: 100%;
  /* max-width: fit-content; */
  max-width: max(50%, 500px);
  max-height: calc(100vh - 150px);

  grid-template-columns: repeat(auto-fit, minmax(min(150px, 100%), 1fr));
}

.attachment-remove {
  position: absolute;
  top: 0;
  right: 10px;
  border: 1px solid black;
  border-radius: 50%;
}

.img-prev-container {
  position: relative;
  width: 100%;
  padding-bottom: 75%; /* 4:3 ratio */
  overflow: hidden;
  border-radius: 10px;
}
.video-prev-container {
  position: relative;
  width: 100%;
  /* padding-bottom: 75%;  */
  overflow: hidden;
  border-radius: 10px;
}

.img-prev-container img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.img-error {
  font-size: 12px;
  color: #fff;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.icon_bg:hover {
  background: linear-gradient(#00000026, #8080805e);
  background-color: transparent;
}

.btn-wrapper {
  --wrapper-left: -5px;
  color: black;
  user-select: none;
  position: relative;
  cursor: pointer;
  height: fit-content;
}

.btn-wrapper:hover::before {
  content: '';
  background-color: #f3f3f3;
  cursor: pointer;
  position: absolute;
  height: 100%;
  inset: 0;
  left: var(--wrapper-left);
  border-radius: 2px;
  z-index: -1;
}

.colored-container {
  background-color: rgba(242, 242, 242, 1);
  border-radius: 2px;
  padding: 10px;
}

.play-back-speed-btn {
  background-color: black;
  border-radius: 10px;
  min-width: 35px;
  outline: none;
  border: none;
  color: white;
  padding: 2px 6px;
  font-size: 10px;
}

.slider-wrapper {
  flex: 1;
  position: relative;
  min-width: 100px;
}

.audio-time {
  position: absolute;
  bottom: 5px;
  left: 21px;
  /* font-size: 12px; */
  color: #879096;
  display: none;
}

/* indefinite progress bar */
.linear-progress-bar {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 2px; /* Adjust the height as needed */
  background-color: lightgray;
  overflow: hidden;
  /* position: relative; */
  padding: 0; /* Ensure no padding */
}

.linear-progress-bar .lp-progress {
  width: 100%;
  height: 100%;
  background-color: black; /* Change the color as needed */
  position: absolute;
  left: -100%;
  animation: loading 1.5s infinite;
}

@keyframes loading {
  0% {
    left: -100%;
    width: 100%;
  }
  50% {
    left: 0;
    width: 100%;
  }
  100% {
    left: 100%;
    width: 100%;
  }
}

/* ENd -indefinite progress bar */
/*  */

.chat-file-ext {
  font-size: 10px;
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  color: white;
  font-weight: 500;
  text-transform: uppercase;
}

.file-icon path:nth-child(2) {
  fill: white;
}

.chat-text-message {
  white-space: pre-wrap;
  overflow-wrap: anywhere;
}

.link-in-text {
  overflow-wrap: anywhere;
}

.preview-file {
  width: 100px !important;
}

.reply-container {
  position: relative;
  top: 4px;
  border-top: 1px solid rgba(240, 240, 240, 1);
  border-left: 1px solid rgba(240, 240, 240, 1);
  border-right: 1px solid rgba(240, 240, 240, 1);
  background-color: #fff;
}

.reply-content {
  color: rgba(42, 46, 52, 0.8) !important;
  font-size: 14px;
}

.w-fit-content {
  width: fit-content !important;
}

.media-preview {
  display: flex;
  flex-direction: column;
  position: fixed;
  inset: 0;
  max-height: 100vh;
  background-color: white;
  width: max(400px, 100%);
  padding: 20px;
  z-index: 9999999;
}
.main-prev {
  flex: 1;
  overflow: hidden;
  width: min(100vh, 100%);
  margin: auto;
}

.image-prev-slide .active {
  outline: 2px solid black;

  position: relative;
}

.image-prev-slide .active::after {
  content: '';
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.084);
}

.main-prev img {
  /* background-color: rgba(217, 217, 217, 1); */
  /* max-width: 100%; */
  max-height: 100%;
  width: 100%;
  object-fit: contain;
  height: 100%;
}

.main-prev div:has(img) {
  width: 100%;
  height: 100%;
  padding: 0 !important;
}

.main-prev video {
  /* background-color: rgba(217, 217, 217, 1); */
  /* max-width: 100%; */
  max-height: 100%;
  width: 100%;
  object-fit: contain;
  /* aspect-ratio: unset !important; */
  height: 100%;
}

.image-prev-slide {
  height: 100px;
  display: flex;
  padding: 2px;
  width: 100%;
  /* overflow: auto; */
  border-image-source: fill 1 linear-gradient(rgba(0, 0, 0, 0.177), rgba(255, 255, 255, 0.21));
  gap: 10px;
  scrollbar-width: none;
}

.overflow-auto {
  overflow: auto;
}

.overflow-visible {
  overflow: visible;
}

.image-prev-slide img {
  width: 100px;
  min-width: 100px;
  background-color: rgba(217, 217, 217, 1);

  height: 100%;
  object-fit: cover;
}

.image-prev-slide .selected-img:first-child {
  margin-left: auto;
}

/* .image-prev-slide .selected-img:last-child {
  margin-right: auto;
} */

.image-prev-add {
  font-size: 50px;
  height: 100px;
  width: 100px;
  margin-left: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-prev-slide .selected-img {
  position: relative;
  width: 100px;
  cursor: pointer;
}

.image-prev-slide .delete-btn {
  position: absolute;
  display: none;
  inset: 0;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.66);
}

.active .delete-btn {
  display: flex;
}

.send-btn {
  background-color: rgb(176, 176, 176);
  border: none;
  outline: none;
  border-radius: 50%;
  aspect-ratio: 1;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
}

.send-btn:hover {
  background-color: rgb(133, 133, 133);
}

.send-error {
  font-size: 13px;
  color: red;
  padding: 2px 50px;
}

em-emoji-picker {
  position: absolute;
  left: 0;
  bottom: calc(100% + 10px);
}
.default-reactions em-emoji-picker {
  position: absolute;
  left: 0;
  bottom: 25px !important;
  /* bottom: calc(100% + 10px); */
}

.reply-card {
  background-color: #f5f5f5;
  padding: 5px 10px;
  /*Will reduce the inside content size based on this since using em for child elements  */
  font-size: 14px;
  margin-top: 2px;
  margin-bottom: 2px;
}

.extra-count-card {
  background-color: rgba(238, 238, 238, 1);
  cursor: pointer;
}
.extra-count-card .count {
  font-size: 2em;
  color: rgb(88 88 88);
  font-weight: 600;
  border-radius: 3px;
}

.extra-card-reply-container {
  min-width: 80px;
  aspect-ratio: 1;
}

.clamp-text {
  max-height: 60px;
  -webkit-line-clamp: 3;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.overlay-reply-img {
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  opacity: 0.2;
}

.input-recorder-preview .recorder-audio-preview .audio-container {
  padding: 0 15px !important;
}

.input-recorder-preview .recorder-audio-preview {
  flex: 1;
}

.audio-container .audio-mic-icon {
  border-radius: 20px;
  background-color: #d9d9d9;
  display: grid;
  place-items: center;
}

.sender-name {
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 400px;
  white-space: nowrap;
}

.thin-grey-border {
  border: 1px solid rgb(225, 225, 225);
}

.link-img-container {
  max-height: 200px;
  overflow: hidden;
}

.link-img-container img {
  object-fit: cover;
  height: 100%;
  width: 100%;
  max-width: 100%;
}

.link-prev .link-img-container {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.link-prev .link-title {
  max-width: 100%;
  font-weight: 500;
  font-size: 14px;
}

.link-prev-container a {
  all: unset;
  cursor: pointer;
}

.link-prev .link-description {
  /* max-width: 100%; */
  min-width: 100%;
  width: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  line-break: anywhere;
  font-size: 11px;
  color: grey;
}

.input-link-prev img {
  max-height: 100px;
  min-width: 100px;
  width: 0;
  object-fit: cover;
  object-position: top;
}

.input-link-prev .link-title {
  max-width: 100%;
  font-weight: 500;
  font-size: 14px;
  overflow: hidden;
  white-space: nowrap;
  min-width: 100%;
  text-overflow: ellipsis;
  line-break: anywhere;
  width: 0;
}

.input-link-prev {
  border-top: 1px solid rgba(240, 240, 240, 1);
  border-left: 1px solid rgba(240, 240, 240, 1);
  border-right: 1px solid rgba(240, 240, 240, 1);
}

.input-link-prev .close-btn {
  position: absolute;
  right: 10px;
  top: 10px;
}

.date-card {
  /* position: sticky; */
  /* top: 10px; */
  width: fit-content;
  margin-inline: auto;
  background-color: rgba(255, 255, 255, 1);
  border: 1px solid var(--border-color);
  border-radius: 30px;
  color: rgb(47, 49, 52);
  padding: 2px 12px;
  font-size: 10px;
  z-index: 999;
  font-weight: 500;
}

.status-change-card {
  /* position: sticky; */
  /* top: 10px; */
  font-size: 12px;
  /* font-weight: 500; */
  color: grey;
  /* background: #e7e7e7; */
  width: fit-content;
  margin: 9px auto;
  border-radius: 50px;
  padding: 2px 10px;
}

.default-reactions {
  background-color: #f7f7f7;
  display: flex;
  gap: 5px;
  align-items: center;
  width: fit-content;
  padding: 2px;
  border-radius: 6px;
  margin-top: 2px;
  /* display: none; */
  /* position: absolute;
  bottom: -15px;
  right: 0;
  z-index: 999; */
}

.default-reactions .emoji:hover {
  scale: 1.5;
  transition: scale 200ms ease-in-out;
}

.reaction-container .selected {
  background-color: #f7f7f7;
}

.reaction-container {
  display: flex;
  gap: 5px;
  border-radius: 4px;
}

.sticky-head {
  position: sticky;
  background-color: white;
  top: 0;
}

.group-creation-pop-up {
  position: absolute;
  top: calc(100% + 10px);
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.12);
  border-radius: 6px;
  left: 0;
  z-index: 99;
  background-color: rgba(255, 255, 255, 1);

  min-width: 226px;
}

/* .group-creation-pop-up .item {
  padding: 2px 20px;
} */

.group-creation-head {
  font-weight: 600;
  font-size: 14px;
  white-space: nowrap;
}

.grey-italic {
  color: grey;
  font-style: italic;
  text-align: center;
}

.group-creation-pop-up input {
  padding: 8px 14px;
  border: 1px solid rgba(135, 144, 150, 1);
  border-radius: 5px;
}

.group-creation-pop-up .selected-usr-box {
  border: 1px solid rgba(135, 144, 150, 1);
  border-radius: 5px;
  min-height: 35px;
}

.add-btn-chat {
  border-radius: 0;
}

.flex-1 {
  flex: 1;
}

.radius-3px {
  border-radius: 3px;
}

.user-group-selection-container {
  margin-top: 15px;
  max-height: 300px;
  overflow: auto;
}

.group-creation-pop-up input[type='checkbox'] {
  accent-color: var(--accent-color);
  transform: scale(1.2);
}

.group-creation-pop-up .selected-usr-box {
  border: 1px solid rgba(135, 144, 150, 1);
  border-radius: 5px;
  min-height: 35px;
  padding: 4px 3px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 5px;
}

.group-creation-pop-up .selected_usr {
  border: 1px solid rgba(228, 228, 228, 1);

  width: fit-content;
  padding: 4px;
  border-radius: 3px;
}

.transparent-search {
  border: none !important;
  outline: none;
  width: 100%;
  padding: 0 !important;
}

.group-username {
  white-space: nowrap;
  max-width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.info-card {
  font-size: 12px;
  /* font-weight: 500; */
  color: grey;
  /* background: #e7e7e7; */
  width: fit-content;
  margin: 9px auto;
  border-radius: 50px;
  padding: 2px 10px;
}

.header-border-bottom {
  border-bottom: 1px solid var(--border-color);
}

/* .reaction-msg-card-wrapper:hover .default-reactions {
  display: flex;
} */

/* BOX MESSAGE CARDS */

.box-message-card {
  background-color: white;
  border: 1px solid var(--border-color);
  border-radius: 4px;
  padding: 5px 9px;
  max-width: 75%;
}

.box-message-full-name {
  color: #2a2e34;
}

.box-message-time {
  color: #87909e;
  margin-left: auto;
  width: fit-content;
}

.box-message-container {
  padding: 4px 6px;
}

.box-reply-card {
  background-color: #f7f8f9;
  position: relative;
  border-radius: 4px;
  border: 1px solid var(--border-color);
  margin-top: 8px;
  margin-bottom: 12px;
}

.white-reply-card {
  background-color: #fff !important;
}
.white-reply-card:hover {
  background-color: #f9f9f9 !important;
}

.box-reply-content-container {
  padding: 9px 9px 9px 13px;
}
.box-reply-card:hover {
  background-color: #eeefef;
}

.box-reply-left-strip {
  width: 4px;
  background-color: #b0bec5;
  border-radius: 3px 0px 0px 3px;
  position: absolute;
  height: 100%;
  left: 0;
  top: 0;
}

.box-reply-full-name {
  color: #656f7d;
}

.box-reply-content {
  color: #656f7d;
}

.box-message-card-sender {
  background-color: #f7f8f9;
}

.right-container {
  flex-direction: row-reverse;
}

.right-grey-card {
  background-color: var(--chat-box-sent-color) !important;
  border: 1px solid var(--chat-box-sent-border-color) !important;
}

.right-grey-card::before {
  border-color: var(--chat-box-sent-border-color) transparent transparent transparent !important;
}

.right-grey-card::after {
  right: -13px !important;
  left: auto !important;
  border-color: var(--chat-box-sent-color) transparent transparent transparent !important;
}
.right-grey-card::before {
  right: -15px !important;
  left: auto !important;
}

.left-grey-card {
  background-color: var(--chat-box-sent-color) !important;
}

.left-grey-card::after {
  left: -13px !important;
  right: auto !important;
  border-color: #f7f8f9 transparent transparent transparent !important;
}
.left-grey-card::before {
  left: -15px !important;
  right: auto !important;
}

.talk-bubble {
  margin: 0px 10px;
  display: inline-block;
  position: relative;
  width: 60%;
  max-width: 500px;
  height: auto;
  background-color: var(--chat-box-received-color);
}
.box-border {
  border: 8px solid #666;
}
.box-round {
  border-radius: 30px;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
}

/* Right triangle placed top left flush. */
.tri-right.left-top:before {
  content: ' ';
  position: absolute;
  width: 0;
  height: 0;
  left: -14px;
  right: auto;
  top: -1px;
  bottom: auto;
  border: 14px solid;
  border-color: var(--border-color) transparent transparent transparent;
}

.tri-right.left-top:after {
  content: ' ';
  position: absolute;
  width: 0;
  height: 0;
  left: -12px;
  right: auto;
  top: 0px;
  bottom: auto;
  border: 16px solid;
  border-color: var(--chat-box-received-color) transparent transparent transparent;
}

.chat-tab_active {
  /* padding: 1px 1px 8px 1px; */
  white-space: nowrap;
  /* border-bottom: 2px solid #3454d1; */
  font-size: 13px;
  font-weight: 500;
  cursor: pointer;
}

.chat-tab_inactive {
  /* border-bottom: 2px solid transparent; */
  white-space: nowrap;
  cursor: pointer;
  /* padding: 1px 1px 8px 1px; */
  /* border-bottom: 1.6px solid red; */
  font-size: 13px;
  font-weight: 500;
  color: #87909e;
  opacity: 0.5;
}

.chat-tab-count {
  color: var(--accent-color);
  height: 22px;
  min-width: 22px;
  background-color: var(--accent-bg-color);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  padding: 5px;
}

.accent-bg-color {
  background-color: var(--accent-color);
}

.update-tab-strip {
  position: absolute;
  bottom: 0;
  transition-property: width, left;
  transition-duration: 200ms;
  transition-timing-function: ease-in;
  height: 2px;
}

.add_item_button {
  padding: 12px 6.5px;
  border-radius: 4px;
}

.btn-active-accent .add_item_button {
  background-color: var(--accent-bg-color);
}

.chat-group-user-select:hover {
  background-color: #f0f1f3;
}

.box-message-options {
  visibility: hidden;
  margin: auto 0;
  border: 1px solid #f1f1f1;
  padding: 5px;
  border-radius: 50px;
  cursor: pointer;
}

.box-message-container .box-message-options-active {
  visibility: visible !important;
}

.box-message-container:hover .box-message-options {
  visibility: visible !important;
}
/* For highlighting the selecōted reply msg */
.msg-highlight {
  animation: msghightlight 1500ms ease-in-out 200ms forwards;
}

@keyframes msghightlight {
  0% {
    background-color: rgb(238, 238, 238);
  }

  100% {
    background-color: initial;
  }
}

/* For text inside svg */
.media-preview text,
.message-list-wrapper text {
  font-size: 6px;
}

.box-message-options:hover {
  background-color: var(--border-color);
}

.box-msg-recorded-audio {
  background-color: transparent !important;
  padding: 6px 8px !important;
}

.mt-100-center {
  margin-top: calc(100% / 2);
}

.responsive-comments-container {
  position: relative;
  top: 25px;
  display: none;
}
/*Responsive */
@media (max-width: 576px) {
  .chat-top-container {
    display: block !important;
    margin: 55px 0px 0px 0px;
    padding: 0px 10px;
  }
  .chat-welcome-container {
    display: none !important;
  }
  .chat-list-content {
    position: absolute;
    top: var(--top-bar-height);
    left: 0;
    right: 0;
    background: #fff;
  }
  .message-list-wrapper {
    background-color: #f2f2f2;
  }
  .chat-input-container {
    padding: 0px 0em 0.9375em 0em !important;
  }

  .task-single-card .chat-list-content {
    position: relative !important;
  }
  .chat-tab-icon {
    display: none;
  }
  .task-chat-container {
    z-index: 8;
  }
  .update-padding {
    padding: 0px 8px;
  }
  .responsive-comments-container {
    display: block;
  }
  .task-modal-view .responsive-comments-container {
    top: 0;
  }
  .list_condainer {
    overflow: auto;
  }
}

.sticky-profile-pic {
  position: sticky;
  top: 10px;
}

.members-count {
  color: #5c5f62;
  font-size: 13px;
  max-width: 230px;
  /* min-width: 100%; */
  width: fit-content;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.group-member-details-container {
  position: absolute;
  top: calc(100% + 15px);
  font-size: 13px;
  left: 0;
  width: max-content;
  z-index: 9999;
  padding: 10px 12px;
  box-shadow: 2px 4px 6px 5px #4444440f;
  background-color: white;
  border-radius: 4px;
}

.group-edit {
  right: 0;
  left: auto;
  max-height: calc(100vh - var(--top-bar-height) - 200px);
  min-height: 300px;
  overflow: auto;
}

.group-type-label {
  border-radius: 4px;
  width: fit-content;
  padding: 0px 5px;
  height: fit-content;
  font-size: 10px;
  background-color: #1ca977;
  color: white;
}

/* file drag and drop  */
.drag-drop-container {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  text-align: center;
  color: white;
  pointer-events: none; /* Make the overlay transparent to mouse events */
  font-size: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 999; /* Ensure it's above other elements */
  pointer-events: none; /* Overlay doesn't interfere with other events */
}

.chat-error-banner {
  position: absolute;
  top: 60px;
  width: 100%;
  z-index: 99;
  background-color: white;
  font-size: 12px;
  font-weight: 500;
  color: red;
  text-align: center;
  padding: 3px 0px;
}
