.time-history-date {
  padding: 2px 6px;
  border: 0.5px solid var(--hover);
  border-radius: 5px;
  min-width: 85px;
  height: 28px;
  display: flex;
  justify-content: center;
  cursor: pointer;
  background: #fff;
  color: var(--main-text-color, #2a2e34);
}
.time-history-date:hover {
  border: 0.5px solid var(--input-border);
}

.head-container {
  display: flex;
  align-items: center;
  gap: 4px;
}

.time-log-container {
  padding: 20px;
  margin: 16px;
  border-radius: 4px;
  border: 1px solid var(--divider, #f0f0f0);
  background: #fff;
}

.report-heading {
  color: #07082f;
  font-family: 'Plus Jakarta Sans';
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.table-info-footer {
  color: #67677f;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.bar:hover {
  background-color: red;
}

/* graphical report  */

.graph-info-label {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 5px;
}

.graph-info-container {
  font-size: 14px;
  font-weight: 500;
  width: 25vw; /* Remove quotes */
}
.bar-graph-info-container {
  display: flex;
  justify-content: flex-start;
  padding: 10px;
  margin: 10px 0 0 35px;
  font-size: 14px;
  font-weight: 500;
}

.heading-title {
  display: flex;
  align-items: center;
  gap: 0.25rem;
}
.report-filter {
  display: flex;
  gap: 0.25rem;
  margin: 10px 0px;
}

@media screen and (max-width: 768px) {
  .time-log-container {
    margin-top: 115px !important;
  }
  .team-report-table {
    margin-top: 15px !important;
    padding: 0;
  }
  .time-log-container {
    padding: 16px;
  }
  .report-filter {
    display: flex;
    gap: 10px;
    margin-top: 1.25rem;
    flex-direction: column;
    /* display: flex;
        gap: 10px;
        margin-top: 1.25rem;
        flex-direction: row;
        flex-wrap: wrap; */
  }
  .fit-width {
    width: fit-content;
  }
  .heading-icon {
    display: none;
  }
  .graph-info-container {
    width: auto;
  }
}
