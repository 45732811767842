.view-end {
  display: flex;
  justify-content: flex-end;
}

.modal_view_label_end {
  height: 100vh;
  width: 25vw;
  background-color: white;
}

.client-form-section {
  /* --screen-height: 100vh;
  --top-padding: 30px;
  --head-font-size: 18px;
  --offset-size: 75px;
  max-height: calc(
    var(--screen-height) - var(--top-padding) - var(--head-font-size) - var(--offset-size)
  );
  overflow-y: auto;
  padding: 1px 16px 30px 16px; */
  --screen-height: 100vh;
  --top-padding: 52px;
  --button-space: 64px;
  height: calc(var(--screen-height) - var(--top-padding) - var(--button-space));
  max-height: calc(var(--screen-height) - var(--top-padding) - var(--button-space));

  overflow-y: auto;
  padding: 1px 16px 30px 16px;
}

.client-add-button-container {
  display: flex;
  /* justify-content: end; */
  justify-content: flex-end;
  align-items: center;
  padding: 15px;
  gap: 10px;
}

.client-form-head {
  font-size: 16px;
  margin: 0;
  font-weight: 600;
}

.mb-16 {
  margin-bottom: 8px;
}

@media (max-width: 768px) {
  .modal_view_label_end {
    width: 45vw;
  }
}

@media (max-width: 400px) {
  .modal_view_label_end {
    width: 100vw;
  }
}
