@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800&display=swap');

.top-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
}

.dark-bg {
  background-color: #333;
  color: white;
}

.light-bg {
  background-color: white;
  color: black;
}

.calendar-selector {
  border-radius: 10px;
  box-shadow: 1px 2px 25px -5px rgba(0, 0, 0, 0.1), 1px 8px 10px -6px rgba(0, 0, 0, 0.1);
  padding: 8px 20px;
  margin: 5px 0px;
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  min-width: 180px;
}

.calendar-container {
  border-radius: 10px;
  padding: 20px;
  top: calc(100% + 10px);
  min-width: 180px;
  user-select: none;
  min-height: 390px;
}

.calendar-container table {
  width: 100%;
  cursor: default;
  table-layout: fixed;
  border-spacing: 5px;
  border-collapse: separate;
}

.calendar-container th {
  font-weight: 400;
  color: #3d3d3d;
}

tr {
  font-size: 14px;
}

.calendar-container tr,
.calendar-container td,
.calendar-container th {
  text-align: center;
}

/* .calendar-container .selected {
    border: 1px solid red;
    border-radius: 10px;
} */
.light-bg .selected {
  /* border: 1px solid red; */
  /* background-color: black !important;
  color: white !important; */
  border-radius: 50%;
  border: 1px solid #2a2e34;
  color: #2a2e34;
}
.light-bg .abs-selected {
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--Base-Color, #f2f3f8);
  position: absolute;
  border-radius: 50%;
  /* border: 1px solid #2a2e34; */
  width: 30px;
  height: 30px;
  left: 50%;
  top: 50%;
  color: rgb(255, 255, 255);
  transform: translate(-50%, -50%);
}

.light-bg .abs-start-date {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #3454d1;
  position: absolute;
  border-radius: 50%;
  /* border: 1px solid #2a2e34; */
  width: 30px;
  height: 30px;
  left: 50%;
  top: 50%;
  color: white;
  transform: translate(-50%, -50%);
}
.light-bg .abs-end-date {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #3454d1;
  position: absolute;
  border-radius: 50%;
  /* border: 1px solid #2a2e34; */
  width: 30px;
  height: 30px;
  left: 50%;
  top: 50%;
  color: white;
  transform: translate(-50%, -50%);
}
.light-bg .abs-today {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(223, 255, 223, 0.68);
  position: absolute;
  border-radius: 50%;
  /* border: 1px solid #2a2e34; */
  width: 30px;
  height: 30px;
  left: 50%;
  top: 50%;
  color: rgb(10, 159, 10);
  transform: translate(-50%, -50%);
}

.light-bg .month-view .selected,
.light-bg .year-view .selected {
  border-radius: 3px;
}

.month-year-head:hover:not(.disabled) {
  cursor: pointer;
  background-color: rgb(238 238 238 / 81%);
  border-radius: 3px;
  /* color: #879096; */
}

.month-year-head {
  padding: 7px 6px;
}

.light-bg .selected-daily {
  /* border: 1px solid red; */
  /* background-color: black !important;
  color: white !important; */
  background-color: rgba(54, 94, 255, 0.3);
  color: white;
  border-radius: 50%;
  /* border: 1px solid #365eff; */
}

.light-bg .today {
  border-radius: 50%;
  background-color: #365eff;
  color: white !important;
}

.dark-bg .selected {
  background-color: white !important;
  color: black !important;
  border: 1px solid #365eff;
  border-radius: 10px;
}

.calendar-container td > div {
  width: 30px;
  height: 30px;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
}

.calendar-container td {
  color: #879096;
  font-weight: 500;
}

/* .calendar-container .day:hover, */
.calendar-container .block:hover {
  background-color: #80808021;
  border-radius: 50%;
  color: #879096 !important;
}

.calendar-container .month-view .block:hover,
.calendar-container .year-view .block:hover {
  border-radius: 3px;
}

.right-arrow:hover,
.left-arrow:hover {
  background-color: #80808021;
}
.gap-6 {
  gap: 6px;
}
.svgIcn {
  width: 10px;
  height: 10px;
}

.cldrIcn {
  width: 30px;
  height: 30px;
}

.left-arrow,
.right-arrow {
  display: flex;
  place-items: center;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  /* border: none; */
  /* border: 2px solid #8080803d; */
  border: 0px;
  background-color: white;
}

.month-year-picker {
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
}
.day-text {
  text-transform: uppercase;
}
.view {
  display: grid;
  /* grid-template-columns: repeat(3, 1fr); */
  /* flex-wrap: wrap; */
  /* gap: 7px; */
  row-gap: 25px;
  column-gap: 15px;
  font-size: 14px;
}

.block {
  flex: 1;
  padding: 7px;
  cursor: default;
  text-align: center;
}

.date-view th,
.date-view td {
  padding-left: 0 !important;
  width: auto !important;
}

/* updated */
