.chat-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  background-color: #f0f0f5;
  border-radius: 8px;
  padding: 20px;
  height: 90vh;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
}

.chat-box {
  display: flex;
  flex-direction: column-reverse;
  flex-grow: 1;
  padding: 15px;
  overflow-y: auto;
  border: 1px solid #e1e1e6;
  border-radius: 8px;
  background-color: #fff;
  margin-bottom: 15px;
  height: 80%;
}

.message-bubble {
  max-width: 70%;
  padding: 12px 16px;
  margin: 8px 0;
  border-radius: 18px;
  line-height: 1.4;
  font-size: 16px;
}

.user-message {
  align-self: flex-end;
  background-color: #0084ff;
  color: white;
  border-bottom-right-radius: 0;
}

.bot-message {
  align-self: flex-start;
  background-color: #f1f0f0;
  color: #333;
  border-bottom-left-radius: 0;
}

.input-area {
  display: flex;
  align-items: center;
  border-top: 1px solid #e1e1e6;
  padding-top: 10px;
}

.message-input {
  flex-grow: 1;
  padding: 12px;
  border: 1px solid #e1e1e6;
  border-radius: 25px;
  outline: none;
  font-size: 16px;
}

.message-input:focus {
  border-color: #0084ff;
}

.send-button {
  background-color: #0084ff;
  color: white;
  border: none;
  padding: 12px 18px;
  margin-left: 10px;
  border-radius: 25px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.send-button:hover {
  background-color: #005fbb;
}

.chat-input {
  border-radius: 200px !important;
}
