.file-table-container {
  border-radius: 4px 4px 0px 0px;
  max-width: 100vw;
  margin: 0 !important;
  border-radius: 4px;
  border: 1px solid var(--divider, #f0f0f0);
  /* background: #fff; */
  background: #ffffff00 !important;
}

.file-tab-line {
  border-bottom: 1px solid #f0f0f0;
  width: 100%;
  position: absolute;
  top: 50px;
  z-index: 0;
}

.file-tab-contain {
  display: flex;
  gap: 18px;
  padding: 15px 0px 0 24px;
}
.file_tabs {
  position: relative;
  /* border-bottom: 1px solid #f1f1f1; */
  background: #fff;
}

.empty-head {
  color: var(--main-text-color);

  font-size: 13px;
  font-weight: 500;
}

.empty-text {
  color: #87909e;

  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.btn-clr {
}
.empty-outer {
  cursor: default;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 13px 3px 8px 0px;
  padding: 3rem 5px 3rem 5px;
}

.empty-button {
  padding: 3px 18px;
  border-radius: 4px;
  border: 1px solid var(--hover, #eaebef);
  background: #fff;
  cursor: pointer;
  display: flex;
  gap: 3px;
  align-items: center;
  color: var(--Second-text-color, #87909e);
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color: var(--main-text-color, #2a2e34);
  position: relative;
  cursor: pointer;
}

.file-add-tabs-in-active {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border: 0.5px solid var(--hover, #eaebef);
  border-radius: 4px;
  text-decoration: none;
  font-size: 13px;
  font-weight: 500;
  padding: 4px 10px;
  color: var(--Second-text-color, #87909e);
  height: fit-content;
  cursor: pointer;
}

/* file add  */
.file-add-tabs-active {
  color: var(--Base-Color, #3454d1);
  font-size: 13px;
  padding: 4px 10px;
  font-weight: 500;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: fit-content;
  border-radius: 4px;
  background: #e9f2ff;
  cursor: pointer;
}

.file-upload-contain {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 13px 3px 8px 0px;
  border-radius: 4px;
  border: 0.5px dashed var(--Second-text-color, #87909e);
  padding: 3rem 5px 3rem 5px;
}
.upload-text {
  /* color: var(--Base-Color, #3454d1); */
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration-line: underline;
  cursor: pointer;
}

.file-add-tabs-in-active:hover {
  color: var(--Second-text-color, #87909e);
  background-color: #eaebef;
}

.file-uploaded-container {
  padding: 7px 8px;
  margin-bottom: 10px;
  position: relative;
  border-radius: 4px;
  border: 1px solid var(--divider, #f0f0f0);
  background: #fff;
}

.file-uploaded-name {
  color: var(--main-text-color, #2a2e34);
  font-size: 13px;
  font-weight: 500;
}
.file-uploaded-size {
  color: var(--Second-text-color, #87909e);
  font-size: 11px;
  font-weight: 500;
}

.file-icon-outer {
  background: rgba(234, 77, 77, 0.1);
  border-radius: 100px;
  padding: 7px;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.shared-with-container {
  border: 1px solid #f0f0f0;
  border-radius: 4px;
  padding: 7px;
}

.file-upload-progress-container {
  width: 100%;
  background-color: #f3f3f3;
  border-radius: 5px;
  overflow: hidden;
  height: 3px;
  z-index: 99;
}
.file-upload-progress-bar {
  width: 0;
  height: 100%;
  position: relative;
  background-color: var(--accent-color);
  border-radius: 5px;
  animation: indeterminate 1.5s ease-in-out forwards infinite;
}

.popup.right {
  right: 0;
}

.popup.left {
  left: 0;
}

.details-field-name {
  color: var(--Second-text-color, #87909e);
  font-size: 13px;
  font-weight: 500;
  line-height: normal;
  align-items: center;
}

.details-field-value {
  color: var(--main-text-color, #2a2e34);
  font-size: 13px;
  font-weight: 500;
  line-height: normal;
}

/* folder list  */
.folder-view-outer {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(170px, 1fr));
  /* repeat(auto-fill, 2fr); */
  gap: 16px;
  text-align: left;
}

.folder-container {
  border-radius: 4px;
  border: 1px solid var(--border, #f2f3f8);
  /* width: 204px; */
  height: 136px;
  flex-shrink: 0;
  padding: 18px;
  background-color: #fff;
}

.folder-view-type-name {
  font-weight: 500;
  font-size: 14px;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 90%;
}
.folder-size-value {
  color: var(--3rd-text-color, #656f7d);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.position {
  left: 0;
  right: 0;
}

.activity thead th:first-child {
}

.file-status {
  cursor: pointer;
}

.file-status:hover svg path {
  stroke: #2a2e34; /* Hover color, change this to your desired color */
}

/* pop up box  */

.file-pop-up {
  z-index: 9999;
  height: fit-content;
  min-width: 100px;
  position: relative;
  /* top: calc(100% + 5px); */
  border-radius: 5px;
  padding-bottom: 10px;
  /* top: calc(38% + 27px); */
  /* left: calc(100% - 160px) !important; */
  right: 176px;
  bottom: 65px;
}

.file-details-popup-modal {
  position: fixed;
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 100;
}

.file-details-popup-children {
  background: #fff;
  padding: 20px;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.12);
  background-color: #fff;
  border-radius: 4px;
  width: 650px;
  cursor: default;
  /* margin-top: 100px; */
}

.file-details-popup {
  background: #fff;
  padding: 20px;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.12);
  background-color: #fff;
  border-radius: 4px;
  width: 650px;
  cursor: default;
  margin-top: 100px;
}

.file-list-filter-container {
  display: flex;
  gap: 5px;
  overflow-x: scroll;
  scrollbar-width: none;
  max-width: 60vw;
  /* padding: 0 5px; */
}

.left-shadow {
  box-shadow: -6px 0 5px -3px var(--shadow, #091e4229);
}

.right-shadow {
  box-shadow: 6px 0 5px -3px var(--shadow, #091e4229);
}

.both-shadow {
  box-shadow: -6px 0 5px -3px var(--shadow, #091e4229), 6px 0 5px -3px var(--shadow, #091e4229);
}

.file-list-container {
  padding: 24px;
}
.file-list-filter {
  display: flex;
  gap: 5px;
}
.file-list-filter-main {
  display: flex;
  justify-content: space-between;
}
.expand {
  display: none;
}
.physical-item {
  display: flex;
  align-items: baseline;
  gap: 3px;
}
.file-list-table-header {
  display: flex;
  gap: 4px;
  color: #87909e;
}
@media (max-width: 768px) {
  .file-details-popup-children {
    /* width: 100% !important; */
    max-width: 320px !important;
  }
  .file-pop-up {
    left: 46px !important;
  }
  .file-tab-contain {
    padding: 0px !important;
  }
  .file-table-container {
    border: 0px;
  }
  .file-list-container {
    padding: 20px 0px;
  }
  .file-list-filter {
    flex-direction: column;
    gap: 14px;
  }
  .file-list-filter-container {
    flex-direction: column;
  }
  .file-list-filter-main:hover {
    height: max-content;
  }
  .file-list-filter-main {
    height: 3em;
    overflow: hidden;
    margin-top: 113px;
    position: absolute;
    z-index: 10;
    width: 100%;
    background: white;
    padding: 8px 17px;
    box-shadow: -1px 4px 20px #f1f1f1;
  }
  .search_contain > .bodr_none {
    height: 26px;
  }
  .expand {
    display: block;
  }
  .file-list-filter-main:hover .expand {
    height: 40px;
    transform: rotate(90deg);
  }
  .physical-item {
    flex-wrap: wrap;
  }
  .file_tabs {
    /* position: absolute;
    top: 88px; */
    /* padding: 10px 17px 0px 17px; */
    width: 100%;
    z-index: 10;
    border-bottom: 1px solid #f1f1f1;
  }
  .file-system-table .table-container {
    margin-top: 150px;
  }
  .file-tab-line {
    display: none;
  }
}
