.clickable-select-content {
  position: absolute;
  top: calc(100% + 10px);
  z-index: 999;
  background-color: white;
  box-shadow: 0px 0px 2.5px 0.5px #d3d3d3;
  padding: 10px;
}

.black-color {
  color: #2a2e34 !important;
}

.clickable-label {
  color: rgb(135, 144, 158) !important;
  font-size: 13px;
  font-weight: 500;
}
.selected-clickable-label {
  /* color: #2a2e34 !important; */
  font-size: 13px;
  font-weight: 500;
}

.clickable-icon-label svg {
  stroke: rgb(135, 144, 158);
}
.selected-clickable-icon-label svg {
  stroke: #2a2e34;
}
